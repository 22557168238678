<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Info Grafis Progress Pekerjaan <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-right form-control"><option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option></select> 
          </CCardHeader>
          <CCardBody>
              <CChartBar
                :datasets="defaultDatasets"
                :options="defaultOptions"
                :labels="labels"
              /> 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> -->Info Grafis Deviasi Pekerjaan <select style="margin-left:15px;width:100px" @change="loadData2" v-model="tahun2" class="form-control-sm float-right form-control"><option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option></select> 

          </slot>
        </CCardHeader>
        <CCardBody>
          
          <CChartPieExample/>
          
        </CCardBody>    
        </CCard>  
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>

import { CChartBar } from '@coreui/vue-chartjs'
import * as Charts from './index.js'
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  components: {
    ...Charts,CChartBar ,
  },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    },
    defaultDatasets () {
      return [
        {
          label: 'Rencana',
          backgroundColor: '#E0922E',
          data: this.rencanas
        },
        {
          label: 'Realisasi',
          backgroundColor: '#5CA83F',
          data: this.realisasis
        },
        // {
        //   label: 'Deviasi',
        //   backgroundColor: 'rgb(228,102,81,0.9)',
        //   data: this.deviasis
        // }
      ]
    },
    defaultOptions () {
      return {
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            }]
        }
      }
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            labels:[],
            rencanas:[],
            realisasis:[],
            deviasis:[],
            devcolors:[],
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadData: function() {
      let user = JSON.parse(localStorage.getItem('user'));
      let id_kontraktor = user.id_kontraktor;
      if (this.tahun == null){
      // your code here.
        axios
        .get("https://sippd.probowsolution.com/public/pekerjaansaya/2021/"+id_kontraktor)
        .then((response)=>
        {
          
           let nameArray =[];
           let rcn =[];
           let dev =[];
           let real =[];
          $.each(response.data,function(key,val){
                nameArray.push(val.lokasi);
                rcn.push(val.rencana_fisik);
                dev.push(val.deviasi);
                real.push(val.realisasi_fisik);
           });

           console.log(nameArray);
           this.labels = nameArray;
           this.rencanas = rcn;
           this.deviasis = dev;
           this.realisasis = real;
        
          
        })
      }
      else{
        axios
        .get("https://sippd.probowsolution.com/public/pekerjaansaya/"+this.tahun+"/"+id_kontraktor)
        .then((response)=>
        {
          // this.products = response.data;
           let nameArray =[];
            let rcn =[];
           let real =[];
           let dev =[];
          $.each(response.data,function(key,val){
                nameArray.push(val.lokasi);
                rcn.push(val.rencana_fisik);
                dev.push(val.deviasi);
                real.push(val.realisasi_fisik);
           });

           console.log(nameArray);
           this.labels = nameArray;
           this.rencanas = rcn;
           this.deviasis = dev;
           this.realisasis = real;
        })
      }
      
    }
  }
}
</script>